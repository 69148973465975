import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Hice la elección correcta al unirme a Spartan Fitness y al optar por el plan y programa adecuados. ¡Ya he logrado mi cuerpo ideal!",
    name: 'Pedro Gomez',
    status : 'ENTRENADOR'
  },
  {
    image: image2,
    review: 'Los servicios de Spartan Fitness han transformado mi vida. He alcanzado mis metas de fitness de una manera que nunca imaginé.',
    name: 'KEVIN MIRON',
    status: 'COACH'
  },
  {
    image : image3,
    review:' Estoy muy satisfecho con Spartan Fitness. Han hecho que mi viaje en el fitness sea emocionante y efectivo. ¡Los recomiendo altamente!',
    name: 'DANIEL GARCIA',
    status: "CLIENTE"
  }
];